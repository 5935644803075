import axios from "axios";
let url = "https://qkchn.testersgroup.com";
var LoginSignService = {
    GenerateCaptch : function()
    {
        let url1 = `${url}/api/auth/generate-captcha`;
        return  axios.get(url1).then((result)=>
        {
            if (result.status === 200) {
                return { result: result, state: true };
            } else {
                return { result: result, state: false };
            }
        })
    },
    authKey : function()
    {
        let url1 = `${url}/api/auth/auth-key?password=Quickchain.flutter.app@secure`;
        return  axios.get(url1).then((result)=>
        {
            if (result.status === 200) {
                return { result: result, state: true };
            } else {
                return { result: result, state: false };
            }
        })
    },
    loginService : function(data)
    {
        return  axios.post(`${url}/api/auth/login`,data,{
            headers:{
              'Content-Type':'application/json'
            }
          })
    },
    signupService : function(data)
    {
        return  axios.post(`${url}/api/auth/register`,data,{
            headers:{
              'Content-Type':'application/json'
            }
          })
    },
    forgotPass : function(data)
    {
        return  axios.post(`${url}/api/auth/forgot-password`,data,{
            headers:{
              'Content-Type':'application/json'
            }
          })
    },
    resetPassword : function(data)
    {
        return  axios.post(`${url}/api/auth/verify-otp`,data,{
            headers:{
              'Content-Type':'application/json'
            }
          })
    },
    getBalance : function(id,token)
    {
        return  axios.get(`${url}/api/auth/get-balance?walletTypeId=${id}`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token

            }
          })
    },
    withDrawalHistory : function(id,token)
    {
        return  axios.get(`${url}/api/auth/withdraw-history?walletTypeId=${id}`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    getWalletCurrency : function(token)
    {
        return  axios.get(`${url}/api/auth/get-wallet-currencies`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    withdrawBalance : function(data,token)
    {
        return  axios.post(`${url}/api/auth/withdraw-balance`,data,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    updateEmail : function(data,token)
    {
        return  axios.post(`${url}/api/auth/update-email`,data,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    getUserDetails : function(token)
    {
        return  axios.get(`${url}/api/auth/get-user-details`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    updatePassword : function(data,token)
    {
        return  axios.post(`${url}/api/auth/update-password`,data,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    getBankList : function(token)
    {
        return  axios.get(`${url}/api/auth/get-bank-list`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    GetRedbillerAccountName : function(bankcode,accountno,token)
    {
        return  axios.get(`${url}/api/auth/verify-red-biller-bank?bank_code=${bankcode}&account_no=${accountno}`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    updateBankDetails : function(data,token)
    {
        return  axios.post(`${url}/api/auth/update-bank-details`,data,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    getPaymentDetail : function(token)
    {
        return  axios.get(`${url}/api/auth/get-payment-details`,{
            headers:{
              'Content-Type':'application/json',
              "Authorization":'Bearer '+token
            }
          })
    },
    
}
export default LoginSignService