import React, { useState, useEffect } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import "./Dashboard.scss";

const Dashboard = ({ children }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 992);
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("user"));
  const toggleSidebar = () => {
    // Toggle only if the width is less than 992
    if (window.innerWidth < 992) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleResize = () => {
    // Set isOpen to true if width is 992 pixels or greater, otherwise false
    if (window.innerWidth >= 992) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logout = () =>{
    localStorage.clear();
    navigate('/');
  }

  return (
    <div className="d-flex">
      {/* Sidebar */}
      <nav
        className={`dashboard ${isOpen ? 'collapse show' : 'collapse'}`}
      >
        <button className="btnn-close d-lg-none" onClick={toggleSidebar}>
        <i class="fa-solid fa-xmark"></i>
        </button>
        <h2 className="text-center">Quickchain</h2>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/user/dashboard" className="nav-link active"><i className="fa-solid fa-gauge"/>Dashboard</Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link"><i className="fa-solid fa-wallet"/>Crypto Quick Sell</Link>
          </li>
          <li className="nav-item">
            <Link to="/user/transcation" className="nav-link"><img src="/images/transaction-icon.svg" alt="transaction-icon"/>Transaction</Link>
          </li>
          <li className="nav-item">
          <Link to="/user/wallet" className="nav-link"><i className="fa-solid fa-wallet"/>Wallet</Link>
          </li>
          <li className="nav-item">
            <Link to="/user/cashbackwallet" className="nav-link"><i className="fa-solid fa-money-bill"/>Cashback Wallet</Link>
          </li>
          <li className="nav-item">
          <Link to="/user/referralwallet" className="nav-link"><i className="fa-solid fa-wallet"/>Referral Wallet</Link>
          </li>
          <li className="nav-item">
          <Link to="/user/account" className="nav-link"><i className="fa-solid fa-circle-user"/>Account</Link>
          </li>
          <li className="nav-item" onClick={logout}>
          <Link to="/login" className="nav-link"><i class="fa-solid fa-right-from-bracket"/>Logout</Link>
          </li>
        </ul>
      </nav>

      {/* Overlay */}
      {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      {/* Main Content */}
      <main className='flex-fill position-relative'>
        <header className="headerdash d-flex justify-content-between align-items-start align-items-lg-center  crypto-header position-relative ml-250">
          <div className="bg-overlay-header d-sm-none d-block"></div>
          <div className="z-2">
          <button
            className="btn btn-primary btn-menu z-2 d-lg-none"
            onClick={toggleSidebar}
          >
            <img src="/images/menenu.svg" alt="Menu" />
          </button>
          <span className="mx-2">Welcome, {auth.data?auth.data.userDetails.firstName:''} {auth.data?auth.data.userDetails.lastName:''} </span>
          </div>
          <div className="d-flex gap-4 align-items-center z-2">
            <button>
              <img src="/images/notification.svg" alt="Notification" />
            </button>
            <Link to="/user/account" className="nav-link">
              <img src="/images/profile.svg" alt="Profile" />
            </Link>
          </div>
        </header>
        {children}
      </main>
    </div>
  );
};

export default Dashboard;
