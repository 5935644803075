import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Success.scss";
import { Link } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ThemeButton from "../../ThemeButton/ThemeButton";

export default function Success(props) {
    const [show, setShow] = useState(true);

    const handleClose = () => {
      setShow(false)
      props.setSuccessShow(false)
      props.setShow(false);
      props.setAmount("");
      props.setPassword("");

    };
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button className="w-100 d-none" onClick={handleShow}>
          Successfully
        </Button>
  
        <Modal
          show={show}
          onHide={handleClose}
          className="success-popup"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center"></Modal.Title>
          </Modal.Header>
          <div className="d-flex d-lg-block justify-content-center h-100">
          <Modal.Body className="pt-2 pb-4">
            <div className="success-info">
                <img src="/images/success-icon.svg" alt="success-icon"/>
              <h2 className={`text-center mb-4 ${props.amount}`}>Congrats</h2>
              <div className="rw-form mb-4">
              </div>
              <div className="text-center mb-4">
                <p>You have successfully Withdrawn ₦ {props.amount} into your account</p>
              </div>
              <ThemeButton btnName="Done"/>
            </div>
          </Modal.Body>
          </div>
          </div>
        </Modal>
      </>
    );
  }
