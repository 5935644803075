// src/Home.js
import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './Home.scss'
import Footer from '../Footer/Footer';

const Home = () => {
  return (
    <Dashboard>
      <div className='main-content home ml-250'>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <img src="/images/crown.svg" alt="crown" />
                      <div>
                        <h1>Profile Compiletion</h1>
                        <div className="d-flex gap-3 align-items-center">
                          <p className="pr-percentage-text">68%</p>
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Warning example"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar bg-warning"
                              style={{ width: "  64%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-completion-last">
                      <h4>Complete</h4>
                    </div>
                  </div>
                  <div className="wallet-balance position-relative z-2">
                    <div className="wb-overlay"></div>
                    <div className="row">
                      <div className="col-6">
                        <div className="d-grid gap-4">
                          <div>
                            <h5>Wallet Balance</h5>
                            <h4>₦ 5678.97</h4>
                          </div>
                          <div>
                            <h5>Select Currency</h5>
                            <select class="form-select" aria-label="Default select example">
                            <option selected>NGN</option>
                            <option value="1">USD</option>
                            <option value="2">EUR</option>
                            <option value="3">GBP</option>
                            <option value="3">USDT</option>
                            <option value="3">CEDIS</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-grid gap-4">
                          <div>
                            <h5>Cashback Wallet</h5>
                            <h4>₦ 3565.67</h4>
                          </div>
                          <div>
                            <h5>Referral Wallet</h5>
                            <h4>₦ 6575.54</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-activity">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Recent Activity</h3>
                    <button><img src="/images/union.svg" alt="union" /></button>
                  </div>
                  <div className="four-recent d-flex justify-content-between align-items-center">
                    <div className="crad-recent">
                      <button>
                      <img className='trans-active' src="/images/convert.svg" alt="" />
                      <h5 className='text-white'>Transaction</h5>
                      </button>
                    </div>
                    <div className="crad-recent">
                      <button>
                      <img src="/images/sell-arrow.svg" alt="" />
                      <h5>Sell</h5>
                      </button>
                    </div>
                    <div className="crad-recent">
                      <button>
                      <img src="/images/wallet-icon.svg" alt="" />
                      <h5>Wallet</h5>
                      </button>
                    </div>
                    <div className="crad-recent">
                      <button>
                      <img src="/images/account.svg" alt="" />
                      <h5>Accounts</h5>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bitcoin-box">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="inner-bitcoin d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-start gap-3">
                      <div className="bitcoin-icon">
                        <img src="/images/leaf-icons.svg" alt="" />
                      </div>
                      <div className="text-bitcoin">
                        <div className="d-flex gap-2 align-items-center">
                          <h4>Bitcoins (BTC)</h4>
                          <button>
                            <img src="/images/info-icon.svg" alt="" />
                          </button>
                        </div>
                        <h6>Success</h6>
                      </div>
                    </div>
                    <div className="bitcoin-amount">
                        <p>₦ 98,532</p>
                        <span>18-10-2024</span>
                    </div>
                  </div>
                  <div className="inner-bitcoin d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-start gap-3">
                      <div className="bitcoin-icon">
                        <img src="/images/leaf-icons.svg" alt="" />
                      </div>
                      <div className="text-bitcoin">
                        <div className="d-flex gap-2 align-items-center">
                          <h4>Bitcoins (BTC)</h4>
                          <button>
                            <img src="/images/info-icon.svg" alt="" />
                          </button>
                        </div>
                        <h6>Success</h6>
                      </div>
                    </div>
                    <div className="bitcoin-amount">
                        <p>₦ 98,532</p>
                        <span>18-10-2024</span>
                    </div>
                  </div>
                  <div className="inner-bitcoin d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-start gap-3">
                      <div className="bitcoin-icon">
                        <img src="/images/leaf-icons.svg" alt="" />
                      </div>
                      <div className="text-bitcoin">
                        <div className="d-flex gap-2 align-items-center">
                          <h4>Bitcoins (BTC)</h4>
                          <button>
                            <img src="/images/info-icon.svg" alt="" />
                          </button>
                        </div>
                        <h6>Success</h6>
                      </div>
                    </div>
                    <div className="bitcoin-amount">
                        <p>₦ 98,532</p>
                        <span>18-10-2024</span>
                    </div>
                  </div>
                  <div className="inner-bitcoin d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-start gap-3">
                      <div className="bitcoin-icon">
                        <img src="/images/leaf-icons.svg" alt="" />
                      </div>
                      <div className="text-bitcoin">
                        <div className="d-flex gap-2 align-items-center">
                          <h4>Bitcoins (BTC)</h4>
                          <button>
                            <img src="/images/info-icon.svg" alt="" />
                          </button>
                        </div>
                        <h6>Success</h6>
                      </div>
                    </div>
                    <div className="bitcoin-amount">
                        <p>₦ 98,532</p>
                        <span>18-10-2024</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
          </div>
      </div>
    </Dashboard>
  );
};

export default Home;
