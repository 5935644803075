import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import TransactionCard from "../TransactionCard/TransactionCard";
import "./ReferralWallet.scss";
import RequestWithdrawal from "../Modals/RequestWithdrawal/RequestWithdrawal";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ReferralWallet() {

  const auth = JSON.parse(localStorage.getItem("user"));
 
  const [walletHistory,setWalletHistory] = useState("");
  const [minwithdwalLimit,setminwithdwalLimit] = useState("");
  const [maxwithdwalLimit,setmaxwithdwalLimit] = useState("");
  const [withDrawalHistory,setWithDrawalHistory] = useState([]);
  const [walletCurrency,setWalletCurrency] = useState([]);
  const [amount,setAmount] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [successShow,setSuccessShow] = useState(false);

   // Get Balance 
   const getBal = async ()=>{    
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBalance(3,token)
      setWalletHistory(response.data.data.wallet_amount);
      setminwithdwalLimit(response.data.data.min_withdrawable_amount)
      if(response.data.data.kycStatus == 0)
      {
        setmaxwithdwalLimit(response.data.data.max_withdrawable_amount_without_KYC??'')
      }   
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Get WithDrawal History 
  const getWith = async ()=>{    
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.withDrawalHistory(3,token)
       console.log('Response:', response.data.data);
       setWithDrawalHistory(response.data.data);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  useEffect(()=>{
      getBal();
      getWith();
  },[])

  // Handle Request
  const handleRequest = async (e) =>{
    e.preventDefault();

    let data = {amount:amount,password:password,walletTypeId:3,platform:'web'}
    if(validate())
    {
      try {
        setLoading(true);
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.withdrawBalance(data,token)
        //  setAmount("")
        //  setPassword("")
        getWith()
         setSuccessShow(true);
        //  toast.success(response.data.message)
         console.log(response.data.data.message)
         
      } catch (error) {
        if (error.response) {
          setLoading(false);
          console.error('Error response:', error.response.data.message);
          toast.error(error.response.data.message)
        } else if (error.request) { 
          setLoading(false);
          console.error('Error request:', error.request);
        } else {
          setLoading(false);
          console.error('Error message:', error.message);
        }
      }
    }
  }

  // Validate 
  const validate =()=>{
   
    let result = true;
    if(!amount)
    {
      toast.error("Please enter amount");
      return result = false;
    }
    if(Number(amount) < Number(minwithdwalLimit))
    {
      toast.error("Minimum amount 1000 debit");
      return result = false;
    }
    if(maxwithdwalLimit)
    {
        if(Number(amount) > Number(maxwithdwalLimit))
        {
          toast.error(`Maximum amount ${maxwithdwalLimit} debit`);
          return result = false;
        }
    }
    if(Number(amount) > Number(walletHistory))
    {
      toast.error(`Maximum amount ${walletHistory} debit`);
      return result = false;
    }
    if(!password)
    {
      toast.error("Please enter password");
      return result = false;
    }
    return result;
  }

  return (
    <>
      <Dashboard>
        <div className="main-content referralwallet ml-250">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className="fs-3">Referral Balance</h1>
                        {/* <span className="fs-6 fw-lighter">
                          Balance available for withdrawal
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="wallet-balance position-relative z-2">
                    <div className="wb-overlay"></div>
                    <div className="row align-items-center">
                      <div className="col-lg-8">
                        <h5>
                          <i class="fa-solid fa-wallet" /> Referral Balance
                        </h5>
                        <div className="d-grid gap-4 align-items-center">
                          <div>
                            <h4 className="fs-3 fs-lg-1">₦ {walletHistory?walletHistory:0}</h4>
                            {
                              walletHistory >= minwithdwalLimit ?(<>
                              </>):(<>
                                <p className='low-balance-alart text-danger mt-2'><i className="fa-solid fa-circle-info"/> Your balance is too low to initiate a withdrawal process</p>
                              </>)
                            }
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="">
                        {
                           walletHistory >= minwithdwalLimit ?(<>
                             <RequestWithdrawal handleRequest={handleRequest} setAmount={setAmount} setPassword={setPassword} amount={amount} password={password} loading={loading} setSuccessShow={setSuccessShow} successShow={successShow} />
                           </>):(<>
                            <button className="btn btn-theme w-100 text-danger bs-danger-bg-subtle">
                            <span><i class="fa-solid fa-money-bill-wave"/> Balance Low</span>
                           </button>
                           </>)

                          }
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-activity">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-5">
                <div className="coupon-box mb-4">
                    <div class="coupon">
                      <div class="left">
                      <div >Referral Code</div>
                      </div>
                      <div class="center">
                        <div className="d-felx align-item-center">
                          <h2 className="mb-0">1YNOGC37FVE</h2>
                          <span><i class="fa-regular fa-copy fs-3"></i></span>
                        </div>
                      </div>

                      <div class="right">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Recent Activity</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="bitcoin-box">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  {
                    withDrawalHistory.map((item)=>(
                      <TransactionCard name="Referral withdrawal" tag={item.status} amount={`₦ ${item.amount}`} date={`${item.date}`} time={`${item.time}`}/>
                    ))
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Dashboard>
      <ToastContainer />
    </>
  );
}
