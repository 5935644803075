import React, { useEffect, useState } from "react";
import "./Account.scss";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import EmailUpdate from "../EmailUpdate/EmailUpdate";
import PasswordReset from "../AccountPasswordReset/PasswordReset";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';

export default function Account() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [userDetails,setUserDetails] = useState("");

  useEffect(()=>{
  getUserInfo()

  },[])

  const getUserInfo = async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getUserDetails(token)
      console.log(response.data.data)
      setUserDetails(response.data.data)
    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)

      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }
  
  return (
    <Dashboard>
      <div className="main-content ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="setttings">
                <h2 className="text-center mb-4">Account</h2>
                <div className="setting-crad mb-lg-4">
                  <div className="setting-profile d-flex gap-2 gap-lg-3 align-items-center">
                    <img
                      className="img-prof"
                      src="/images/profile-pic.svg"
                      alt="Profile Images"
                    />
                    <div className="setting-info d-flex justify-content-between align-items-lg-center align-items-start w-100">
                      <div className="left-stt">
                        <h2>{userDetails?userDetails.firstname:''} {userDetails?userDetails.lastname:''}</h2>
                        <p>{userDetails?userDetails.email:''}</p>
                        <span className="d-block">₦ 2347</span>
                      </div>
                      <div className="right-stt mt-lg-3">
                        <img
                          src="/images/change-photo.svg"
                          alt="chnage profile"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-nav">
                <h4>Account</h4>
                <div className="setting-nav-box">
                  <ul>
                    <li>
                    <li>
                    {["bottom"].map((placement, idx) => (
                          <EmailUpdate
                            key={idx}
                            placement={placement}
                            name={placement}
                            email={userDetails?userDetails.email:''}
                            getUserInfo={getUserInfo}
                          />
                        ))}
                    </li>
                    </li>
                    <li>
                    {["bottom"].map((placement, idx) => (
                          <PasswordReset
                            key={idx}
                            placement={placement}
                            name={placement}
                          />
                        ))}
                    </li>
                    <li>
                    {["bottom"].map((placement, idx) => (
                          <PaymentDetails
                            key={idx}
                            placement={placement}
                            name={placement}
                          />
                        ))}
                    </li>
                    <li>
                      <Link to="/kyc" className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-user-check"></i>
                          <span>KYC</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-shield-halved"></i>
                          <span>Privacy policy</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-shield-halved"></i>
                          <span>Terms of Services</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-comment"></i>
                          <span>Help & FAQ</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link className="d-flex gap-2 justify-content-between">
                        <span className="d-flex gap-2 align-items-center">
                          <i class="fa-solid fa-right-from-bracket"></i>
                          <span>Logout</span>
                        </span>
                        <img src="/images/arrow-right.svg" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Dashboard>
  );
}
