import { BrowserRouter as Router, Route, Link, Routes, } from "react-router-dom";
import './App.scss';
import Login from "./Components/Login/Login";
import './Fonts/SofiaProRegular.otf';
import SignUp from "./Components/SignUp/SignUp";
import Forgot from "./Components/Forgot/Forgot";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Home from "./Components/HomeScreen/Home";
import Account from "./Components/Account/Account";
import Wallet from "./Components/Wallet/Wallet";
import CashBackWallet from "./Components/CashBackWallet/CashBackWallet";
import ReferralWallet from "./Components/ReferralWallet/ReferralWallet";
import Transcation from "./Components/Transcation/Transcation";
import Admin from "./Components/Login/Admin";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="*" element={<Login />} />

          <Route path="/user" element={<Admin />} >
            <Route path="dashboard" element={<Home />} />
            <Route path="transcation" element={<Transcation />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="cashbackwallet" element={<CashBackWallet />} />
            <Route path="referralwallet" element={<ReferralWallet />} />
            <Route path="account" element={<Account />} />

          </Route>

          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
    </Router>
    </>
  );
}

export default App;
