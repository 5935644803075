import React from "react";
import './Footer.scss'

export default function Footer() {
  return (
    <>
      <div className="footer-bootom d-lg-none d-block">
          <div className="footer-card d-flex align-items-center justify-content-between">
            <div className="footer-card-box foot-active">
              <img src="/images/home-active.svg" alt="" />
            </div>
            <div className="footer-card-box">
              <img src="/images/sell-icon-f.svg" alt="" style={{width: "35px"}} />
              <h5>sell</h5>
            </div>
            <div className="footer-card-box">
              <img src="/images/market.svg" alt="" />
              <h5>market</h5>
            </div>
            <div className="footer-card-box">
              <img src="/images/settings.svg" alt="" />
              <h5>settings</h5>
            </div>
          </div>
      </div>
    </>
  );
}
