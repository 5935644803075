import React, { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./PaymentDetails.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PaymentDetails({ name, ...props }) {
  const [show, setShow] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [redBillerBankL,setRedBillerBankL] = useState([]);
  const [noRedBillerBankL,setNoRedBillerBankL] = useState([]);
  const [acNumber, setAccountNumber] = useState("");
  const [acName, setAccountName] = useState("");
  const [password, setPassword] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [isReadonly, setIsReadonly] = useState(false);
  const [checkRed, SetCheckRed] = useState("");
  const [bank, setSelects] = useState();
  const [inpuloader, setInputLoading] = useState(false);
  const [error, setError] = useState();
  const [loading,setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    bankList()
    paymentDetails()
  },[])

  // Get Bank List
  const bankList = async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBankList(token)
      console.log(response.data.data)
      setNoRedBillerBankL(response.data.data)
      setRedBillerBankL(response.data.data1)
    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Get paymentDetails 
  const paymentDetails = async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getPaymentDetail(token)
      console.log(response.data)
      
    } catch (error) {
      if (error.response) {
        console.log(error.data)
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }



  // Update Payment Details 
  const handlePaymentDetails =async (e)=>{
    e.preventDefault();
    let value = {bank:bank,bankcode:bankCode,account_name:acName,account_number:acNumber,password:password}
    if(validate()){
      setLoading(true)
      try {
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.updateBankDetails(value,token)
        console.log(response.data.message)
        setAccountNumber("")
        setAccountName("")
        setPassword("")
        setBankCode("")
        setLoading(false)
        setShow(false)
        toast(response.data.message)

      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message)
          toast(error.response.data.message)
          setError(error.response.data.message)
          setLoading(false)

        } else if (error.request) { 
          console.error('Error request:', error.request);
          setLoading(false)

        } else {
          console.error('Error message:', error.message);
          setLoading(false)

        }
      }
    }
  }

  // handle Select
  const handleSelect = (event) => {
    setAccountName("");
    setAccountNumber("");
    setSelects(event.target.value);
    const checkRedbiller =
      event.target[event.target.selectedIndex].getAttribute("data-red");
    const bcode =
      event.target[event.target.selectedIndex].getAttribute("data-bankCode");
      setBankCode(bcode);
      SetCheckRed(checkRedbiller);
  
    if (checkRedbiller == 0) {
      setIsReadonly(false);
    } else {
      setIsReadonly(true);
     
    }
  };

  const handleAccountNo = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleV = (event) => {
    setAccountNumber(event.target.value);
    if (acNumber.length == 10 && checkRed == 1) {
      setInputLoading(true);
      getAccountName();
    }
  };

  // Redbiller Account Name
  const getAccountName = async () => {
    try {
      const token = (auth.data.item.access_token); 
      // let bankcode = bankCode;
      // let accountno = acNumber;
       let bankcode = '000013';
       let accountno = '0158541672';
      const response = await LoginSignService.GetRedbillerAccountName(bankcode,accountno,token)
      console.log(response.data.data.account_name)
      setAccountName(response.data.data.account_name);
      setInputLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setInputLoading(false);

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setInputLoading(false);

      } else {
        console.error('Error message:', error.message);
      }
    }
    
  };

   
   // Validate 
   const validate =()=>{
   
    let result = true;
    if(!bank)
    {
      toast.error("Enter Bank Name");
      return result = false;
    }
    if(!bankCode)
    {
      toast.error("Enter Bankcode");
      return result = false;
    }
    if(!acName)
    {
      toast.error(`Enter Name`);
      return result = false;
    }
    if(!acNumber)
    {
      toast.error(`Enter Account No`);
      return result = false;
    }
    if(!password)
    {
      toast.error("Please enter password");
      return result = false;
    }
    return result;
  }
  return (
    <>
      <span
        onClick={handleShow}
        className="paymentdetailsbtn d-flex gap-2 justify-content-between"
      >
        <span className="d-flex gap-2 align-items-center">
          <i class="fa-solid fa-building-columns"></i>
          <span>Payment Details</span>
        </span>
        <img src="/images/arrow-right.svg" alt="" />
      </span>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <Offcanvas
              className="paymentdetailsoffcanvas"
              show={show}
              onHide={handleClose}
              {...props}
            >
              <Offcanvas.Header
                closeButton
                className="mt-2 pt-2 pb-0"
              ></Offcanvas.Header>
              <Offcanvas.Body className="pt-0">
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-6">
                    <div className="paymentdetailsinfo-box text-white">
                      <h2 className="text-center mt-1">Payment Details</h2>
                      {/* <p className="approved text-info">
                    Complete below to update email
                  </p> */}
                      <div className="status-card my-4 p-3">
                        <Form onSubmit={handlePaymentDetails}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            {/* <Form.Label>Current Password</Form.Label> */}
                            <Form.Select className="w-100" aria-label="Default select example" onChange={handleSelect}>
                              <option>Select</option>
                              {
                                noRedBillerBankL.map((item)=>(
                                  <option
                                  value={item.bank_name}
                                  data-bankCode={item.bank_code}
                                  data-red={item.is_redbiller}
                                  >{item.bank_name}</option>
                                ))
                              }
                              {
                                redBillerBankL.map((item)=>(
                                  <option value={item.bank_name}
                                  data-bankCode={item.bank_code}
                                  data-red={item.is_redbiller}>{item.bank_name}</option>
                                ))
                              }
                              
                            </Form.Select>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Acc Number /BTC Address/Email"
                              value={acNumber}
                              onChange={handleAccountNo}
                              onKeyUp={handleV}
                            />
                             {inpuloader ? (
                                <>
                                  <div className="text-white">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Loading...
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Acc Name (add Bank Name if self-entry)"
                              value={acName}
                              onChange={(e) => setAccountName(e.target.value)}
                              readOnly={isReadonly}
                            />
                            
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="password"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="text-danger">{error}</span>
                          </Form.Group>
                          <ThemeButton btnName="Update" loading={loading} />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer/>
          </div>
        </div>
      </div>
    </>
  );
}
