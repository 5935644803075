import React from "react";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import TransactionCard from "../TransactionCard/TransactionCard";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Transcation.scss";

export default function Transcation() {
  const [key, setKey] = useState("home");
  return (
    <>
      <Dashboard>
        <div className="main-content transcation ml-250">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className="fs-3">Transaction</h1>
                        <span className="fs-6 fw-lighter">
                          View pertinent details of transactions carried out
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="trans-tab mt-4">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="home" title="Crypto">
                  <div className="bitcoin-box">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Wallets">
                  <div className="bitcoin-box">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                        <TransactionCard
                          name="Wallet withdrawal"
                          tag="Approved"
                          amount="₦ 98,532"
                          date="18-10-2024"
                          time="10:36:42"
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            </div>
            </div>
            <Footer />
          </div>
        </div>
      </Dashboard>
    </>
  );
}
