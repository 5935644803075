import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./EmailUpdate.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EmailUpdate({ name,email,getUserInfo, ...props }) {
  const [show, setShow] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [newemail,setNewEmail] = useState("");
  const [confirmemail,setConfirmemail] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");

  const handleEmailUpdate = async (e)=>{
    e.preventDefault();
    let data = {current_email:email,new_email:newemail,confirm_email:confirmemail,password:password}
    if(validate())
    {
      try {
        setLoading(true);
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.updateEmail(data,token)
        setNewEmail("")
        setConfirmemail("")
        setPassword("")
        setError("")
        setShow(false)
        getUserInfo()
        console.log(response.data.data.message)
        toast.error(response.data.data.message)
      } catch (error) {
        if (error.response) {
          setLoading(false);
          
          console.error('Error response:', error.response.data.message);
          toast.error(error.response.data.message)
          setError(error.response.data.message)
         
        } else if (error.request) { 
          setLoading(false);
          console.error('Error request:', error.request);
        } else {
          setLoading(false);
          console.error('Error message:', error.message);
        }
      }
    }
  }

  // Validate 
  const validate =()=>{
   
    let result = true;
    if(!email)
    {
      toast.error("Please enter current email");
      return result = false;
    }
    if(!newemail)
    {
      toast.error("Enter new email");
      return result = false;
    }
    if(!confirmemail)
    {
      toast.error(`Enter confirm email`);
      return result = false;
    }
    if(!password)
    {
      toast.error("Please enter password");
      return result = false;
    }
    return result;
  }

  return (
    <>
      <span
        onClick={handleShow}
        className="emailupdatebtn d-flex gap-2 justify-content-between"
      >
        <span className="d-flex gap-2 align-items-center">
          <i class="fa-solid fa-envelope"></i>
          <span>Update Email</span>
        </span>
        <img src="/images/arrow-right.svg" alt="" />
      </span>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <Offcanvas
              className="emailupdateoffcanvas"
              show={show}
              onHide={handleClose}
              {...props}
            >
              <Offcanvas.Header
                closeButton
                className="mt-2 pt-2 pb-0"
              ></Offcanvas.Header>
              <Offcanvas.Body className="pt-0">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-6">
                    <div className="emailinfo-box text-white">
                  <h2 className="text-center mt-1">Update Email</h2>
                  <p className="approved text-info">
                    Complete below to update email
                  </p>
                  <div className="status-card my-4 p-3">
                    <Form onSubmit={handleEmailUpdate}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        {/* <Form.Label>Current Password</Form.Label> */}
                        <Form.Control
                          type="email"
                          placeholder="Current Email"
                          value={email}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="email" placeholder="New Email"
                        value={newemail}
                        onChange={(e)=>setNewEmail(e.target.value)}
                        />
                      
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="email"
                          placeholder="Confirm New Email"
                          value={confirmemail}
                          onChange={(e)=>setConfirmemail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="password"
                          placeholder="QuickChain Password"
                          value={password}
                          onChange={(e)=>setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <span className="text-danger">{error}</span>
                      <ThemeButton btnName="Update" loading={loading} />
                    </Form>
                  </div>
                </div>
                    </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer/>
          </div>
        </div>
      </div>
    </>
  );
}
