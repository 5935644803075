import React from "react";
import "./TransactionCard.scss";
import WalletInfo from "../WalletInfo/WalletInfo";

export default function TransactionCard(props) {
  function getButtonColor(status) {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "text-warning";
      case "DECLINED":
        return "text-danger";
      case "APPROVED":
        return "";
      case "PROCESSING":
        return "text-info";
      case "COMPLETED":
        return "button-completed";
      default:
        return "";
    }
  }
  return (
    <>
      <div className="transcard">
        <div className="inner-bitcoin d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-start gap-3">
            <div className="bitcoin-icon">
              <img src="/images/leaf-icons.svg" alt="" />
            </div>
            <div className="text-bitcoin">
              <div className="d-flex gap-2 align-items-center">
                <h4>{props.name}</h4>
                <button>
                  {["bottom"].map((placement, idx) => (
                    <WalletInfo
                      key={idx}
                      placement={placement}
                      name={placement}
                      status={props.tag}
                      amount={props.amount}
                      date={props.date}
                      time={props.time}
                      color={getButtonColor(
                        props.tag
                      )}
                    />
                  ))}
                </button>
              </div>
              <h6 className={`${getButtonColor(
                                        props.tag
                                      )}`}>{props.tag}</h6>
            </div>
          </div>
          <div className="bitcoin-amount">
            <p>{props.amount}</p>
            <span>
              {props.date}
              <span className="ms-1">{props.time}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
